import React from 'react'
import ReactMarkdown from 'react-markdown'
import { graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { DefaultLayout } from '../layouts/defaultlayout'
import { GalleryWrapper, Container, Divider } from '../components'

import { Navigation } from './_navigation'

export const query = graphql`
  query {
    logo: directus {
      ...LogoFragment
    }
    aboutus: directus {
      ...AboutusFragment
    }
  }
`

// eslint-disable-next-line import/no-default-export
export default ({ data }) => (
  <DefaultLayout>
    <Navigation logo={data.logo.logo.logo.imageUrl} />
    <Container>
      <Helmet>
        <meta name="description" content="Anfahrt" />
      </Helmet>
      <h1>{data.aboutus.aboutus.title}</h1>
      <ReactMarkdown source={data.aboutus.aboutus.text} />
      <Divider />
      <GalleryWrapper images={data.aboutus.aboutus.pictures} />
    </Container>
  </DefaultLayout>
)
